import { useRef } from 'react';
import FooterBanner from '../footer/FooterBanner';
import bannerImage from '../../images/park-avenue/mark-belford-park-avenue-footer.webp';
import palmTree from '../../images/palm-tree.svg';
import palmTreeThin from '../../images/palm-tree-thin.svg';
import styles from '../styles/HotelCalifornia.module.css';
import stylesCollection from '../styles/Collections.module.css';
import showHiddenText from '../modules/ShowText';
import removeHiddenText from '../modules/RemoveText';
import scrollTo from '../modules/ScrollTo';

const HotelCalifornia = () => {

    const anchor1 = useRef(null);
    const anchor2 = useRef(null);
    const anchor3 = useRef(null);
    const anchor4 = useRef(null);

    return(
        <main>
            <div className='page-marker'></div>
            <section className='content content-top'>
                <div className='row center-it'>
                    <div className='col-sm-12'>
                        <div className='row'>
                            <div className='col-lg-4 col-xxl-4'></div>
                            <div className='col-lg-6 col-xxl-4'>
                                <div className={styles.heroHeader}>
                                    <h1>
                                        <div className={styles.adj1}>hotel</div><div className={styles.adj2}>California</div>
                                    </h1>
                                    <div className={styles.palmTree}>
                                        <picture>
                                            <img src={palmTree} alt='Mark Belford hand sketched Palm Tree' height='100%' width='100%' />
                                        </picture>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            {/*<div className='col-md-2 lrg-1'></div>*/}
                            <div className='col-xxxxl-2'></div>
                            <div className='col-xxs-6 col-sm-6 col-lg-3 col-xxxxl-2'>
                                <div className={styles.imageBtn}>
                                <a onClick={() => {scrollTo(anchor1)}}>
                                    <picture>
                                        <img src={require('../../images/hotel-california/hotel-california-block-2.webp')} alt='Mark Belford Silver Gown' height='100%' width='100%' className={styles.topImageBlock} />
                                    </picture>
                                    </a>
                                </div>
                                <div className={styles.imageBtnNames}>
                                    Desire
                                </div>
                            </div>
                            <div className='col-xxs-6 col-sm-6 col-lg-3 col-xxxxl-2'>
                                <div className={styles.imageBtn}>
                                    <a onClick={() => {scrollTo(anchor2)}}>
                                    <picture>
                                        <img src={require('../../images/hotel-california/hotel-california-block-1.webp')} alt='Mark Belford Silver Gown' height='100%' width='100%' className={styles.topImageBlock} />
                                    </picture>
                                    </a>
                                </div>
                                <div className={styles.imageBtnNames}>
                                    Envy
                                </div>
                            </div>
                            <div className='col-xxs-6 col-sm-6 col-lg-3 col-xxxxl-2'>
                                <div className={styles.imageBtn}>
                                    <a onClick={() => {scrollTo(anchor3)}}>
                                    <picture>
                                        <img src={require('../../images/hotel-california/hotel-california-block-3.webp')} alt='Mark Belford Silver Gown' height='100%' width='100%' className={styles.topImageBlock} />
                                    </picture>
                                    </a>
                                </div>
                                <div className={styles.imageBtnNames}>
                                    Lusty
                                </div>
                            </div>
                            <div className='col-xxs-6 col-sm-6 col-lg-3 col-xxxxl-2'>
                                <div className={styles.imageBtn}>
                                    <a onClick={() => {scrollTo(anchor4)}}>
                                    <picture>
                                        <img src={require('../../images/hotel-california/hotel-california-block-4.webp')} alt='Mark Belford Silver Gown' height='100%' width='100%' className={styles.topImageBlock} />
                                    </picture>
                                    </a>
                                </div>
                                <div className={styles.imageBtnNames}>
                                    Tasty
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-4 col-xxl-5'></div>
                            <div className='col-lg-6 col-xl-5 col-xxl-4 col-xxxl-3'>
                                <div className={stylesCollection.contentTopParagraph}>
                                    <p>
                                    <br/>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-3 lrg-1'></div>
                        </div>
                    </div>
                </div> 
            </section>
            {/*---------- /TOP BLOCK ----------*/}
            {/*---------- DRESS 1 ----------*/}
            <a className={stylesCollection.pageAnchor} ref={anchor1} />
            <section className='content'>
                <div className='row'>
                    <div className={`col-md-6 col-lg-8 col-xxl-6 ${stylesCollection.fullBleedFlushLeft}`}>
                        <figure>
                            <picture>
                                <source media='(max-width:500px)' srcSet={require('../../images/hotel-california/mark-belford-hotel-california-1-xs.webp')} />
                                <source srcSet={require('../../images/hotel-california/mark-belford-hotel-california-1-lg.webp')} />
                                <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing the Mark Belford Hotel California Collection' height='100%' width='100%' loading='lazy' />
                            </picture>
                        </figure>
                    </div>
                    <div className='col-md-11 col-lg-5 col-xl-6'>
                        <div className='row'>
                            <div className='col-xxl-2'></div>
                            <div className='col-sm-10 col-md-12 col-xl-10 col-xxl-8'>
                                <div className='vertical-center-block'>
                                    <h2 className='hot-pink'>A mesmerizing<br/>silhouette<br/>that commands<br/>attention</h2>
                                    <p>
                                    Fall in love with the structured corseted bodice, adorned with our signature cups, that beautifully accentuates your curves. The voluminous cocktail skirt, supported by layers of pleated silk organza in the hand-made crinoline underneath, creates a mesmerizing silhouette that commands attention. Completing the ensemble with a couture finish, the box-pleated silk trim adds an extra touch of refinement.
                                    </p>
                                    <div className='e-comerce-link-2' onMouseEnter={() => {showHiddenText();}} onMouseLeave={() => {removeHiddenText();}} >
                                        <a href='https://www.markbelfordcouture.com/' target='blank' >Shop<br/>Mark Belford<br/>Couture</a>
                                        <div className='hidden-text-mask'></div>
                                        <div className='hidden-text'>
                                            You're<br/>Worth It!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*---------- /DRESS 1 ----------*/}
            {/*---------- DRESS 2 ----------*/}
            <a className={stylesCollection.pageAnchor} ref={anchor2} />
            <section className='content'>
                <div className='row'>
                    <div className={`col-lg-6 ${stylesCollection.fullBleedFlushLeft}`}>
                        <figure>
                            <picture>
                                <source media='(max-width:500px)' srcSet={require('../../images/hotel-california/mark-belford-hotel-california-2-xs.webp')} />
                                <source srcSet={require('../../images/hotel-california/mark-belford-hotel-california-2-lg.webp')} />
                                <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing the Mark Belford Hotel California Collection' height='100%' width='100%' loading='lazy' />
                            </picture>
                        </figure>
                    </div>
                    <div className='col-lg-5 col-xl-6'>
                        <div className='row'>
                            <div className='col-xxl-2'></div>
                            <div className='col-md-10 col-xxl-8'>
                                <div className='vertical-center-block'>
                                    <h2 className='hot-pink'>A mesmerizing<br/>silhouette<br/>that commands<br/>attention'</h2>
                                    <p>
                                    Fall in love with the structured corseted bodice, adorned with our signature cups, that beautifully accentuates your curves. The voluminous cocktail skirt, supported by layers of pleated silk organza in the hand-made crinoline underneath, creates a mesmerizing silhouette that commands attention. Completing the ensemble with a couture finish, the box-pleated silk trim adds an extra touch of refinement.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*---------- /DRESS 2 ----------*/}
            {/*---------- DRESS 3 ----------*/}
            <a className={stylesCollection.pageAnchor} ref={anchor3} />
            <section className='content'>
                <div className='row'>
                    <div className={`col-lg-6 ${stylesCollection.fullBleedFlushLeft}`}>
                        <figure>
                            <picture>
                                <source media='(max-width:500px)' srcSet={require('../../images/hotel-california/mark-belford-hotel-california-3-xs.webp')} />
                                <source srcSet={require('../../images/hotel-california/mark-belford-hotel-california-3-lg.webp')} />
                                <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing the Mark Belford Hotel California Collection' height='100%' width='100%' loading='lazy' />
                            </picture>
                        </figure>
                    </div>
                    <div className='col-lg-5 col-xl-6'>
                        <div className='row'>
                            <div className='col-xxl-2'></div>
                            <div className='col-md-10 col-xxl-8'>
                                <div className='vertical-center-block'>
                                    <h2 className='hot-pink'>A mesmerizing<br/>silhouette<br/>that commands<br/>attention'</h2>
                                    <p>
                                    Fall in love with the structured corseted bodice, adorned with our signature cups, that beautifully accentuates your curves. The voluminous cocktail skirt, supported by layers of pleated silk organza in the hand-made crinoline underneath, creates a mesmerizing silhouette that commands attention. Completing the ensemble with a couture finish, the box-pleated silk trim adds an extra touch of refinement.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*---------- /DRESS 3 ----------*/}
            {/*---------- DRESS 4 ----------*/}
            <a className={stylesCollection.pageAnchor} ref={anchor4} />
            <section className='content'>
                <div className='row'>
                    <div className={`col-lg-9 col-xxl-6 ${stylesCollection.fullBleedFlushLeft}`}>
                        <figure>
                            <picture>
                                <source media='(max-width:500px)' srcSet={require('../../images/hotel-california/mark-belford-hotel-california-4-xs.webp')} />
                                <source srcSet={require('../../images/hotel-california/mark-belford-hotel-california-4-lg.webp')} />
                                <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing the Mark Belford Hotel California Collection' height='100%' width='100%' loading='lazy' />
                            </picture>
                        </figure>
                    </div>
                    <div className='col-lg-5 col-xl-6'>
                        <div className='row'>
                            <div className='col-xxl-2'></div>
                            <div className='col-md-10 col-xl-10 col-xxl-8'>
                                <div className='vertical-center-block'>
                                    <h2 className='hot-pink'>A mesmerizing<br/>silhouette<br/>that commands<br/>attention'</h2>
                                    <p>
                                    Fall in love with the structured corseted bodice, adorned with our signature cups, that beautifully accentuates your curves. The voluminous cocktail skirt, supported by layers of pleated silk organza in the hand-made crinoline underneath, creates a mesmerizing silhouette that commands attention. Completing the ensemble with a couture finish, the box-pleated silk trim adds an extra touch of refinement.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*---------- /DRESS 4 ----------*/}
            {/*---------- PALM TREE MESSAGE ----------*/}
            <section className='content middle-big-pad'>
                <div className='row'>
                    <div className='col-xxs-5 col-sm-5 col-md-4'></div>
                    <div className='col-xxs-7 col-sm-5 col-md-4'>
                        <div className={styles.palmText}>
                            
                            <div className={styles.palmTreeBottom}>
                                <picture>
                                    <img src={palmTreeThin} />
                                </picture>
                            </div>
                            <h2 className='hot-pink text-align-right'>Welcome<br/>to the<br/>Hotel California</h2>
                        </div>
                    </div>
                </div>
            </section>
            {/*---------- /PALM TREE MESSAGE ----------*/}
            <FooterBanner bannerImage={bannerImage} />
        </main>
    );
};

export default HotelCalifornia;