import FooterBanner from '../footer/FooterBanner';
import bannerImage from '../../images/dior/mark-belford-sweedish-prince-footer.webp';
import styles from '../styles/SwedishPrince.module.css';
import stylesCollection from '../styles/Collections.module.css';
import showHiddenText from '../modules/ShowText';
import removeHiddenText from '../modules/RemoveText';

const SwedishPrince = () => {
    return (
        <main>
            <div className='page-marker'></div>
            <section className='content content-top'>
                <div className='row center-it'>
                    <div className='col-sm-12'>
                        <div className='row'>
                            <div className='col-md-1 col-xl-2 col-xxxl-4'></div>
                            <div className={`col-sm-9 col-md-7 col-lg-6 col-xl-5 col-xxxl-4 ${styles.hero}`}>
                                <div className={styles.heroHeader}>
                                    <h1>
                                        <div className={styles.adj1}>the</div><div className={styles.adj2}>Swedish</div><div className={styles.adj3}>Prince</div>
                                    </h1>
                                    <div className={styles.locationText}>
                                        <div className={styles.line1}>at</div>
                                        <div className={styles.line2}>Dior</div>
                                    </div>
                                </div>
                                <picture className='image'>
                                    <source media='(max-width:500px)' srcSet={require('../../images/dior/mark-belford-dior-1.webp')} />
                                    <img src={require('../../images/dior/mark-belford-dior-1.webp')} alt='Fredrik Robertson wearing the Sweedish Prince cape by Mark Belford' height='auto' width='100%' />
                                </picture>
                            </div>
                            <div className='col-sm-12 order-md-3'>
                                <div className='row'>
                                    <div className='col-md-2 col-xl-3 col-xxl-4 col-xxxl-5'></div>
                                    <div className='col-sm-6 col-md-4 col-xxl-3'>
                                        <div className={stylesCollection.contentTopParagraph}>
                                            <div className='top-text-lrg'>Fredrik Robertson <br/>dominates</div>
                                            <p>
                                            the front row of the Dior Couture Show in Paris, magnificent in Mark Belford Couture. Black silk Peau De Soie structured shift dress with signature Mark Belford cuff.<br/>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            {/*
            <section className='content content-top center-it'>
                <div className='row'>
                    <div className='col-xxl-1'></div>
                    <div className={`col-lg-7 col-xxl-6 ${styles.thePrince}`}>
                        <div className={styles.heroHeader}>
                            <h1>
                                <div className={styles.adj1}>the</div><div className={styles.adj2}>Swedish</div><div className={styles.adj3}>Prince</div>
                            </h1>
                            <div className={styles.locationText}>
                                <div className={styles.line1}>at</div>
                                <div className={styles.line2}>Dior</div>
                            </div>
                        </div>
                        <div className={styles.swedishPrinceHeroPic}>
                            <picture>
                                <source media="(max-width:768px)" srcset={require('../../images/dior/mark-belford-dior-1-sml.webp')}></source>
                                <img src={require('../../images/dior/mark-belford-dior-1.webp')} height='100%' width='100%' />
                            </picture>
                        </div>
                    </div>
                    <div className='col-md-10 col-lg-5 col-xxl-3 relative'>
                        <div className={styles.contentTopParagraph}>
                            <p>Fredrik Robertson, dominates the front row of the Dior Couture Show in Paris, magnificent in Mark Belford Couture.  Black silk Peau De Soie structured shift dress with signature Mark Belford cuff. An extraordinary voluminous silk cape featuring Marks silk flowers and jet-black pearls.<br/>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            */}
            <section className='content'>
                <div className='row'>
                    <div className={`col-md-6 col-lg-8 col-xxl-6 ${stylesCollection.fullBleedFlushLeft}`}>
                        <figure>
                        <picture>
                            <img src={require('../../images/dior/mark-belford-dior-6.webp')} height='100%' width='100%' /> 
                        </picture>
                        </figure>
                    </div>
                    <div className='col-md-12 col-lg-5 col-xl-6'>
                        <div className='row'>
                            <div className='col-xxl-2'></div>
                            <div className='col-sm-10 col-md-12 col-xl-10 col-xxl-6'>
                                <div className='vertical-center-block'>
                                    <h2 style={{color:'#fd1d86'}}>
                                        I Create <br/>Characters<br/>with my<br/>Style
                                    </h2>
                                    <p>‘A lot of people think I dress for attention: I don’t. I just happen to love fashion and have always liked the idea that what I wear changes how people look at and think of me. I don’t feel that I have one style; I create characters with my style. I like to dress how I feel, and I like to dress for the occasion. If I’m going to a gala, I do gala; at the couture in Paris, I go all out. I don’t limit myself, and I love to have fun with fashion.’<br/><span className='signature'>Fredrik Anderson – Vogue</span></p>
                                    <div className='e-comerce-link-2' onMouseEnter={() => {showHiddenText();}} onMouseLeave={() => {removeHiddenText();}} >
                                        <a href='https://www.markbelfordcouture.com/' target='blank' >Shop<br/>Mark Belford<br/>Couture</a>
                                        <div className='hidden-text-mask'></div>
                                        <div className='hidden-text'>
                                            You're<br/>Worth It!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='content middle-big-pad'>
                <div className='row'>
                    <div className='col-lg-6 order-lg-2 order-xs-1'>
                        <div className='row'>
                            <div className='col-md-10 col-lg-8'>
                                <picture className='image image-border'>
                                    <img src={require('../../images/dior/mark-belford-dior-9.webp')} alt='Model standing by mirror in the Butterfield 8 dress' height='auto' width='100%' />
                                </picture>
                            </div>
                            <div className='col-md-10 col-lg-10'>
                                <picture className='image image-border'>
                                    <img src={require('../../images/dior/mark-belford-dior-2.webp')} alt='Model standing by mirror in the Butterfield 8 dress' height='auto' width='100%' />
                                </picture>
                            </div>
                            <div className='col-md-10 col-lg-5'>
                                <picture className='image image-border'>
                                    <img src={require('../../images/dior/mark-belford-dior-8.webp')} alt='Model standing by mirror in the Butterfield 8 dress' height='auto' width='100%' />
                                </picture>
                            </div>
                            <div className='col-xs-12 col-md-6 col-xxl-8'>
                                <h2 style={{color:'#fd1d86'}}>Black Silk<br/>Peau<br/>De Soie</h2>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 order-lg-1 order-xs-2'>
                        <div className='row justify-content-end-lg'>
                            <div className='top-text-lrg'>An extraordinary<br/>voluminous<br/>silk cape</div>
                            <p>
                            featuring Marks silk flowers and jet-black pearls. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.<br/>
                            <br/>
                            </p>
                            <div className='col-xs-12 col-md-8 col-lg-10 col-xxxl-8'>
                                <picture className='image image-border'>
                                    <img src={require('../../images/dior/mark-belford-dior-4.webp')} alt='Model looking into mirror in the Butterfield 8 coat and dress' height='auto' width='100%' />
                                </picture>
                            </div>
                            <div className='col-xs-12 col-md-6 col-lg-8 col-xxxl-5'>
                                <picture className='image image-border'>
                                    <img src={require('../../images/dior/mark-belford-dior-7.webp')} alt='Model looking into mirror in the Butterfield 8 coat and dress' height='auto' width='100%' />
                                    {/* <div className={stylesCollection.clickMeTag}>
                                        Kick<br/>Me
                                    </div>*/}
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

             <FooterBanner bannerImage={bannerImage} />
        </main>
    );
};

export default SwedishPrince;