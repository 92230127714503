import FooterBanner from '../footer/FooterBanner';
import bannerImage from '../../images/park-avenue/mark-belford-park-avenue-footer.webp';
import styles from '../styles/ParkAvenue.module.css';
import stylesCollection from '../styles/Collections.module.css';
import showHiddenText from '../modules/ShowText';
import removeHiddenText from '../modules/RemoveText';

export default function ParkAvenue(){
    return(
        <main>
            <div className='page-marker'></div>
            <section className='content content-top'>
                <div className='row center-it'>
                    <div className='col-sm-12'>
                        <div className='row'>
                            <div className='col-lg-1 col-xl-2 col-xxxl-4'></div>
                            <div className='col-sm-6 col-lg-4 col-xl-3 col-xxxl-2 order-md-2'>
                                <div className={styles.heroHeader}>
                                    <h1>
                                        <div className={styles.adj1}>the</div><div className={styles.adj2}>Park</div><div className={styles.adj3}>Avenue</div>
                                    </h1>
                                </div>
                            </div>
                            <div className='col-sm-9 col-md-5 col-lg-4 col-xxxl-3 order-md-1'>
                                <picture className='image'>
                                    <source media='(max-width:500px)' srcSet={require('../../images/park-avenue/mark-belford-park-avenue-top.webp')} />
                                    <img src={require('../../images/park-avenue/mark-belford-park-avenue-top.webp')} alt='Model standing by mirror in the Butterfield 8 dress' height='auto' width='100%' />
                                </picture>
                            </div>
                            <div className='col-sm-12 order-md-3'>
                                <div className='row'>
                                    <div className='col-md-2 col-xl-3 col-xxl-4 col-xxxl-5'></div>
                                    <div className='col-md-4 col-xxl-3'>
                                        <div className={stylesCollection.contentTopParagraph}>
                                            <div className='top-text-lrg'>Elevate your<br/>Park Avenue<br/>style with our<br/>captivating creation,</div>
                                            <p>
                                            the "Park Avenue" Couture cocktail dress. Crafted from luxurious deep Bordeaux-colored French silk chiffon, this exquisite masterpiece embodies the perfect blend of sophistication and sensuality.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='content'>
                <div className='row'>
                    <div className="col-md-6 col-lg-6 col-xxl-6">
                        <div className={stylesCollection.fullBleed}>
                            <figure>
                                <picture className='image-full'>
                                    <source media='(max-width:500px)' srcSet={require('../../images/park-avenue/mark-belford-park-avenue-1-xs.webp')} />
                                    <source media='(max-width:824px)' srcSet={require('../../images/park-avenue/mark-belford-park-avenue-1-sm.webp')} />
                                    <source srcSet={require('../../images/park-avenue/mark-belford-park-avenue-1-lg.webp')} />
                                    <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing the Mark Belford Park Avenue Cocktail Dress' height='100%' width='100%' loading='lazy' />
                                </picture>
                            </figure>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-5 col-xl-6'>
                        <div className='row'>
                            <div className='col-xxl-2'></div>
                            <div className='col-sm-10 col-md-12 col-xl-10 col-xxl-8'>
                                <div className='vertical-center-block blk'>
                                    <h2 style={{color:'#fd1d86'}}>
                                        An Aura<br/>of Elegance<br/>and Grace
                                    </h2>
                                    <p className='white-text'>Prepare to be mesmerized by the structured corseted bodice, meticulously designed to accentuate your curves and create a flawless silhouette. Mark's signature cups enhance and celebrate your natural beauty, adding an extra touch of allure. Witness the artistry of razor-sharp rouching that delicately highlights the sensuality of your curves, creating an enchanting visual effect.</p>
                                    <div className='e-comerce-link-2 blk-bg' onMouseEnter={() => {showHiddenText();}} onMouseLeave={() => {removeHiddenText();}} >
                                        <a href='https://www.markbelfordcouture.com/' target='blank' >Shop<br/>Mark Belford<br/>Couture</a>
                                        <div className='hidden-text-mask'></div>
                                        <div className='hidden-text'>
                                            You're<br/>Worth It!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='content middle-big-pad'>
                <div className='row'>
                    <div className='col-lg-6 order-lg-2 order-xs-1'>
                        <div className='row'>
                            <div className='col-md-10 col-lg-12'>
                                <picture className='image'>
                                    <source media='(max-width:500px)' srcSet={require('../../images/park-avenue/mark-belford-park-avenue-3-xs.webp')} />
                                    <source srcSet={require('../../images/park-avenue/mark-belford-park-avenue-3-lg.webp')} />
                                    <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing the Mark Belford Park Avenue Cocktail Dress' height='100%' width='100%' loading='lazy' />
                                </picture>
                            </div>
                            <div className='col-md-10 col-lg-8'>
                                <picture className='image'>
                                    <source media='(max-width:500px)' srcSet={require('../../images/park-avenue/mark-belford-park-avenue-2-xs.webp')} />
                                    <source srcSet={require('../../images/park-avenue/mark-belford-park-avenue-2-lg.webp')} />
                                    <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing the Mark Belford Park Avenue Cocktail Dress' height='100%' width='100%' loading='lazy' />
                                </picture>
                            </div>
                            <div className='col-xs-12 col-md-6 col-xxl-8'>
                                <h2 style={{color:'#fd1d86'}}>Park Avenue<br/>Exclusivity</h2>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 order-lg-1 order-xs-2'>
                        <div className='row justify-content-end-lg'>
                            <p className='lrg top-adj-1'>
                            With every step, you'll exude an aura of elegance and grace, effortlessly embodying the essence of Park Avenue style. Experience the magic of this Couture piece and let it transform you into the epitome of glamour and sophistication. Discover the allure of Park Avenue exclusivity.<br/>
                            <br/>
                            </p>
                            <div className='col-xs-12 col-md-8 col-lg-10 col-xxxl-8'>
                                <picture className='image'>
                                    <source media='(max-width:500px)' srcSet={require('../../images/park-avenue/mark-belford-park-avenue-4-xs.webp')} />
                                    <source srcSet={require('../../images/park-avenue/mark-belford-park-avenue-4-lg.webp')} />
                                    <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing the Mark Belford Park Avenue Cocktail Dress' height='100%' width='100%' loading='lazy' />
                                </picture>
                            </div>
                            <p className='sml'>
                            <br/>
                            <br/>
                            With every step, you'll exude an aura of elegance and grace, effortlessly embodying the essence of Park Avenue style. Experience the magic of this Couture piece and let it transform you into the epitome of glamour and sophistication. Discover the allure of Park Avenue exclusivity.<br/>
                            <br/>
                            <br/>
                            </p>  
                        </div>
                    </div>
                </div>
            </section>
            <FooterBanner bannerImage={bannerImage} />
        </main>
    );
};