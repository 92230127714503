import styles from '../styles/homeStyles.module.css';
import { NavLink } from '../header/HeaderElements';
import headerAppearanceLight from '../modules/headerAppearanceLight';
import showHiddenText from '../modules/ShowText';
import removeHiddenText from '../modules/RemoveText';
import classNames from 'classnames';
import imageSmall from '../../images/butterfield8/mark-belford-butterfield8-Lrg-2.webp';
import imageMedium from '../../images/butterfield8/mark-belford-butterfield8-Lrg-2.webp';
import imageLarge from '../../images/butterfield8/mark-belford-butterfield8-Lrg-1.webp';
import imageXLarge from '../../images/butterfield8/mark-belford-butterfield8-Lrg-1.webp';
import landingTextImage from '../../images/elements/Gorgeous.svg';
import FooterBanner from '../footer/FooterBanner';
import bannerImage from '../../images/home/mark-belford-home-3.webp';

export default function MarkBelfordWebsite(){
    return(
        <main>
            <section className={`${styles.landingPageTop} ${styles.landingBackground}`}>
                <div className={styles.landingPageTopInner}>

                <div className='row'>
                    <div className='col-sm-12'>
                    <div className={`row ${styles.landingPageTopCallOut}`}>
                        <div className={`col-sm-9 col-md-8 col-xl-7 col-xxl-6 col-xxxl-5 ${styles.landingTextImg}`}>
                            <picture>
                                <img src={landingTextImage} alt='text saying You are Gorgeous' height='100%' width='100%' />
                            </picture>
                        </div>
                    </div>
                    <div className={styles.landingPageTopBottom}>
                    <div className='row'>
                        <div className='col-md-1'></div>
                        <div className='col-sm-6 col-md-4 col-lg-3 col-xxxl-2'>
                            <NavLink to="/Contact" onClick={() => {headerAppearanceLight();}}>
                            <div className={classNames(styles.landingSans, styles.landingSansTop)} >
                                <div className='spec-text-4'>Click<br/>here</div>
                                and lets start your journey towards owning your very own hand made work <span className='no-wrap'>of art by</span>
                                <div className={styles.style2}>Mark Belford<span style={{fontSize:"24px"}}>.</span></div>
                            </div>
                            </NavLink>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>


                    
                </div>
                <div className='header-marker'></div>
            </section>
            <section className={`content large-adjust ${styles.homeSectionBTF1}`}>
                <div className={styles.belowTheFoldHeader}>
                    Drapped in confidence, Styled in Authenticity.
                </div>
                <div className='row'>
                    <div className='col-xl-2 col-xxl-7 lrg-1'></div>
                    <div className='col-xl-8 col-xxl-3'>
                        <h1 className='call-out-txt'>Mark Bellford's pret-a-porter capsule collection is couture's rebellious little sister-flawlessly tailored, endlessly wearable, and dripping in quiet luxury.</h1>
                    </div>
                    <div className='col-xl-2 col-xxl-3 lrg-1'></div>
                </div>
            </section>
            <section className='content middle-big-pad'>
                <div className='row'>
                    <div className={`col-md-6 col-lg-8 col-xxl-6 ${styles.fullBleedFlushLeft}`}>
                        <NavLink to="/ParkAvenue">
                        
                        <figure>
                            <picture className='image'>
                                <source media='(max-width:500px)' srcSet={require('../../images/home/mark-belford-black-lace-2-xs.webp')} />
                                <source media='(max-width:824px)' srcSet={require('../../images/home/mark-belford-black-lace-2-sm.webp')} />
                                <source srcSet={require('../../images/home/mark-belford-black-lace-2.webp')} /> 
                                <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing Mark Belfords black lace Park Avenue Dress' height='412' width='567' loading='lazy' />
                            </picture>
                            <div className={styles.imageLinkCaption}>
                                <div className={styles.line1}>
                                view the
                                </div>
                                <div className={styles.line2}>
                                Park Avenue
                                </div>
                                <div className={styles.line3}>
                                Collection
                                </div>
                            </div>
                        </figure>
                        </NavLink>
                    </div>
                    <div className='col-md-11 col-lg-5 col-xl-6'>
                        <div className='row'>
                            <div className='col-xxl-2'></div>
                            <div className='col-sm-10 col-md-12 col-xl-10 col-xxl-8'>
                                <div className='vertical-center-block'>
                                <h2>Turning inner<br/>beauty into a<br/>full-blown<br/>power move.</h2>
                                <div>
                                <picture className='image'>
                                <source srcSet={require('../../images/home/mark-belford-black-lace-2-slice.webp')} /> 
                                <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing Mark Belfords black lace Park Avenue Dress' height='412' width='567' loading='lazy' />
                            </picture>
                                </div>
                                <p>The Mark Belford Couture label was born in Toronto in 2002, as if the universe just knew it needed something truly exceptional. Inspired by the polished yet playful spirit of Edith Head's designs, Mark's sketches immediately became the blueprint for the Studio, setting the stage for everything that followed.</p>
                                { /* <div className='e-comerce-link-2' > */ }
                                <div className='e-comerce-link-2' onMouseEnter={() => {showHiddenText();}} onMouseLeave={() => {removeHiddenText();}} >
                                    <a href='https://www.markbelfordcouture.com/' target='blank' >Shop<br/>Mark Belford<br/>Couture</a>
                                    <div className='hidden-text-mask'></div>
                                    <div className='hidden-text'>
                                        You're<br/>Worth It!
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='content middle-big-pad'>
                <div className='row'>
                    <div className='col-lg-6 order-lg-2 order-xs-1'>
                        <div className='row'>
                            <div className='col-md-10 col-lg-12'>
                                <NavLink to="/Butterfield8">
                                <figure>
                                    <picture className='image'>
                                        <img srcSet={`${imageSmall} 320w, ${imageMedium} 680w, ${imageLarge}   960w, ${imageXLarge} 1980w`} src={imageXLarge} alt="Model wearing Mark Belfords Butterfield 8 Dress" height='auto' width='100%' loading='lazy' />
                                    </picture>
                                    <div className={styles.imageLinkCaption}>
                                        <div className={styles.line1}>
                                        view the
                                        </div>
                                        <div className={styles.line2}>
                                        Butterfield 8
                                        </div>
                                        <div className={styles.line3}>
                                        Collection
                                        </div>
                                    </div>
                                </figure>
                                </NavLink>
                            </div>
                            <div className='row'>
                                <div className='col-xs-12 col-md-6 col-xxl-9'>
                                    <h2 style={{color:'#fd1d86', paddingTop:6}}>Impeccable<br/>tailoring and<br/>architectural<br/>hand draping</h2>
                                </div>
                            </div>
                            <div className='col-md-8 col-lg-6'>
                                <NavLink to="/BurmeseRuby">
                                <figure>
                                    <picture className='image'>
                                        <source media='(max-width:500px)' srcSet={require('../../images/burmese-ruby/mark-belford-burmese-ruby-3-xs.webp')} />
                                        <source srcSet={require('../../images/burmese-ruby/mark-belford-burmese-ruby-3-lg.webp')} />
                                        <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing Mark Belfords black lace Park Avenue Dress' height='100%' width='100%' loading='lazy' />
                                    </picture>
                                    <div className={styles.imageLinkCaption}>
                                        <div className={styles.line1}>
                                        view the
                                        </div>
                                        <div className={styles.line2}>
                                        Burmese Ruby
                                        </div>
                                        <div className={styles.line3}>
                                        Collection
                                        </div>
                                    </div>
                                </figure>
                                </NavLink>
                            </div>


                        </div>
                    </div>
                    <div className='col-lg-6 order-lg-1 order-xs-2'>
                        <div className='row justify-content-end-lg'>
                            <p className='lrg'>
                            <div className='spec-text-3'>The debut<br/>Couture<br/>Red Carpet<br/>Collection</div>was practically discovered by Giorgio Beverly Hills (we'll let that speak for itself) and launched at The Academy Awards-because why not start with a little bit of drama? What followed was nothing short of inevitable: a legacy of impeccable taste, high-octane glamour, and a touch of 'where's this been all my life?' elegance. Mark's designs don't just follow trends-they define them, effortlessly blending classic sophistication with a bold, fresh take that leaves the competition wondering why they decided to slap their label on a dress made in a factory far, far away ... but, hey, we're not judging.<br/>
                            <br/>
                            </p>
                            <div className='col-xs-12 col-md-8 col-lg-10 col-xxxl-8'>
                                <NavLink to="/AcademyGown">
                                <figure>
                                    <picture className='image'>
                                        <source srcSet={require('../../images/home/mark-belford-silver-dress-1.webp')} /> 
                                        <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing Mark Belfords silver Academy Gown' height='100%' width='100%' loading='lazy' />
                                    </picture>
                                    <div className={styles.imageLinkCaption}>
                                        <div className={styles.line1}>
                                        view the
                                        </div>
                                        <div className={styles.line2}>
                                        Academy Gown
                                        </div>
                                        <div className={styles.line3}>
                                        Collection
                                        </div>
                                    </div>
                                </figure>
                                </NavLink>
                            </div>
                            <p className='sml'>
                            <br/>
                            <br/>
                            <div className='spec-text-3'>The debut<br/>Couture<br/>Red Carpet<br/>Collection</div>was practically discovered by Giorgio Beverly Hills (we'll let that speak for itself) and launched at The Academy Awards-because why not start with a little bit of drama? What followed was nothing short of inevitable: a legacy of impeccable taste, high-octane glamour, and a touch of 'where's this been all my life?' elegance. Mark's designs don't just follow trends-they define them, effortlessly blending classic sophistication with a bold, fresh take that leaves the competition wondering why they decided to slap their label on a dress made in a factory far, far away ... but, hey, we're not judging.<br/>
                            <br/>
                            <br/>
                            </p>  
                        </div>
                    </div>
                </div>
            </section>
            <FooterBanner bannerImage={bannerImage} />
            {/*
            <section>
            <div className={styles.midPageImage}>
                    <picture>
                    <source srcSet={pattern2} /> 
                    <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing Mark Belfords silver Academy Gown' height='100%' width='100%' loading='lazy' />
                    </picture>
                </div>
                <div className={styles.footerImage}>
                    <picture>
                    <source srcSet={pattern1} /> 
                    <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing Mark Belfords silver Academy Gown' height='100%' width='100%' loading='lazy' />
                    </picture>
                </div>
            </section>*/}
        </main>
    );
};