import FooterBanner from '../footer/FooterBanner';
import bannerImage from '../../images/burmese-ruby/mark-belford-burmese-ruby-footer.webp';
import styles from '../styles/BurmeseRuby.module.css';
import stylesCollection from '../styles/Collections.module.css';
import showHiddenText from '../modules/ShowText';
import removeHiddenText from '../modules/RemoveText';

const BurmeseRuby = () => {
    return(
        <main>
            <div className='page-marker'></div>
            <section className='content content-top'>
                <div className='row center-it'>
                    <div className='col-sm-12'>
                        <div className='row'>
                            <div className='col-lg-1 col-xl-2 col-xxxl-4'></div>
                            <div className='col-sm-6 col-lg-4 col-xl-3 col-xxxl-2 order-md-2'>
                                <div className={styles.heroHeader}>
                                    <h1>
                                        <div className={styles.adj1}>the</div><div className={styles.adj2}>Burmese</div><div className={styles.adj3}>Ruby</div>
                                    </h1>
                                </div>
                            </div>
                            <div className='col-sm-9 col-md-5 col-lg-4 col-xxxl-3 order-md-1'>
                                <picture className='image'>
                                    <source media='(max-width:500px)' srcSet={require('../../images/burmese-ruby/mark-belford-burmese-ruby-top.webp')} />
                                    <img src={require('../../images/burmese-ruby/mark-belford-burmese-ruby-top.webp')} alt='Model standing by mirror in the Butterfield 8 dress' height='auto' width='100%' />
                                </picture>
                            </div>
                            <div className='col-sm-12 order-md-3'>
                                <div className='row'>
                                    <div className='col-md-2 col-xl-3 col-xxl-4 col-xxxl-5'></div>
                                    <div className='col-md-4 col-xxl-3'>
                                        <div className={stylesCollection.contentTopParagraph}>
                                            <div className='top-text-lrg'>Ignite the night<br/>with our stunning<br/>masterpiece,</div>
                                            <p>
                                            the deep ruby red "Burmese Ruby Gown." This exquisite couture creation, hand draped with delicate precision by the visionary Mark Belford, is a celebration of structured elegance and <span className='no-wrap'>alluring charm.</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='content'>
                <div className='row'>
                    <div className="col-md-6 col-lg-6 col-xxl-6">
                        <div className={stylesCollection.fullBleed}>
                            <figure>
                                <picture className='image-full'>
                                    <source media='(max-width:500px)' srcSet={require('../../images/burmese-ruby/mark-belford-burmese-ruby-1-xs.webp')} />
                                    <source media='(max-width:824px)' srcSet={require('../../images/burmese-ruby/mark-belford-burmese-ruby-1-sm.webp')} />
                                    <source srcSet={require('../../images/burmese-ruby/mark-belford-burmese-ruby-1-lg.webp')} />
                                    <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing the Mark Belford Burmese Ruby dress' height='100%' width='100%' loading='lazy' />
                                </picture>
                            </figure>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-5 col-xl-6'>
                        <div className='row'>
                            <div className='col-xxl-2'></div>
                            <div className='col-sm-10 col-md-12 col-xl-10 col-xxl-8'>
                                <div className='vertical-center-block blk'>
                                    <h2 style={{color:'#fd1d86'}}>
                                        Flawlessly<br/>Accentuate<br/>Your Silhouette
                                    </h2>
                                    <p className='white-text'>Featuring a sculpted bodice that seamlessly flows into an elaborate pleated evening skirt, this gown exudes a sense of regal allure. The centerpiece of this exceptional masterpiece is Mark's signature mastered bows, a nod to the iconic style of Hollywood legend Nicole Kidman and the legendary Harry Winston Burmese diamond suite.</p>
                                    <div className='e-comerce-link-2 blk-bg' onMouseEnter={() => {showHiddenText();}} onMouseLeave={() => {removeHiddenText();}} >
                                        <a href='https://www.markbelfordcouture.com/' target='blank' >Shop<br/>Mark Belford<br/>Couture</a>
                                        <div className='hidden-text-mask'></div>
                                        <div className='hidden-text'>
                                            You're<br/>Worth It!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='content middle-big-pad'>
                <div className='row'>
                    <div className='col-lg-6 order-lg-2 order-xs-1'>
                        <div className='row'>
                            <div className='col-md-10 col-lg-12'>
                                <picture className='image'>
                                    <source media='(max-width:500px)' srcSet={require('../../images/burmese-ruby/mark-belford-burmese-ruby-2-xs.webp')} />
                                    <source srcSet={require('../../images/burmese-ruby/mark-belford-burmese-ruby-2-lg.webp')} />
                                    <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing the Mark Belford Burmese Ruby dress' height='100%' width='100%' loading='lazy' />
                                </picture>
                            </div>
                            <div className='col-md-10 col-lg-8'>
                                <picture className='image'>
                                    <source media='(max-width:500px)' srcSet={require('../../images/burmese-ruby/mark-belford-burmese-ruby-4-xs.webp')} />
                                    <source srcSet={require('../../images/burmese-ruby/mark-belford-burmese-ruby-4-lg.webp')} />
                                    <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing the Mark Belford Burmese Ruby dress' height='100%' width='100%' loading='lazy' />
                                </picture>
                            </div>
                            <div className='col-xs-12 col-md-6 col-xxl-8'>
                                <h2 style={{color:'#fd1d86'}}>Meticulously<br/>Hand Draped <br/>by Mark Belford</h2>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 order-lg-1 order-xs-2'>
                        <div className='row justify-content-end-lg'>
                            <p className='lrg top-adj-1'>
                            Lined completely in navy blue silk throughout, this gown is the epitome of refined sophistication and luxurious indulgence. Get ready to make a grand entrance at your next soirée Ignite the night with the mesmerizing radiance of "The Burmese Ruby" gown. This exquisite couture creation, meticulously hand draped by the visionary Mark Belford, will captivate all with its structured elegance and irresistible charm.<br/>
                            <br/>
                            </p>
                            <div className='col-xs-12 col-md-8 col-lg-10 col-xxxl-8'>
                                <picture className='image'>
                                    <source media='(max-width:500px)' srcSet={require('../../images/burmese-ruby/mark-belford-burmese-ruby-3-xs.webp')} />
                                    <source srcSet={require('../../images/burmese-ruby/mark-belford-burmese-ruby-3-lg.webp')} />
                                    <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing the Mark Belford Burmese Ruby dress' height='100%' width='100%' loading='lazy' />
                                </picture>
                            </div>
                            <p className='sml'>
                            <br/>
                            <br/>
                            Lined completely in navy blue silk throughout, this gown is the epitome of refined sophistication and luxurious indulgence. Get ready to make a grand entrance at your next soirée Ignite the night with the mesmerizing radiance of "The Burmese Ruby" gown. This exquisite couture creation, meticulously hand draped by the visionary Mark Belford, will captivate all with its structured elegance and irresistible charm.<br/>
                            <br/>
                            <br/>
                            </p> 
                        </div>
                    </div>
                </div>
            </section>
            <FooterBanner bannerImage={bannerImage} />
        </main>
    );
};

export default BurmeseRuby;