import FooterBanner from '../footer/FooterBanner';
import bannerImage from '../../images/fairmont-fashion/mark-belford-fairmont-fashion-footer.webp';
import styles from '../styles/FairmontFashion.module.css';
import stylesCollection from '../styles/Collections.module.css';
import showHiddenText from '../modules/ShowText';
import removeHiddenText from '../modules/RemoveText';


const CAFAawards = () => {
    return (
        <main>
            <div className='page-marker'></div>
            <section className='content content-top'>
                <div className='row center-it'>
                    <div className='col-sm-12'>
                        <div className='row'>
                            <div className='col-lg-1 col-xl-2 col-xxxl-4'></div>
                            <div className='col-sm-6 col-lg-4 col-xl-3 col-xxxl-2 order-md-2'>
                                <div className={styles.heroHeader}>
                                    <h1>
                                        <div className={styles.adj1}>Fairmont</div><div className={styles.adj2}>Fashion</div>
                                    </h1>
                                </div>
                            </div>
                            <div className='col-sm-9 col-md-5 col-lg-4 col-xxxl-3 order-md-1'>
                                <picture className='image'>
                                    <source media='(max-width:500px)' srcSet={require('../../images/fairmont-fashion/mark-belford-FairmontFashion-top.webp')} />
                                    <img src={require('../../images/fairmont-fashion/mark-belford-FairmontFashion-top.webp')} alt='Model standing by mirror in the Butterfield 8 dress' height='auto' width='100%' />
                                </picture>
                            </div>
                            <div className='col-sm-12 order-md-3'>
                                <div className='row'>
                                    <div className='col-md-2 col-xl-3 col-xxl-4 col-xxxl-5'></div>
                                    <div className='col-md-4 col-xxl-3'>
                                        <div className={stylesCollection.contentTopParagraph}>
                                            <div className='top-text-lrg'>Elevate your style<br/>with our captivating<br/>Park Avenue creation,</div>
                                            <p>
                                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat <span className='no-wrap'>nulla pariatur.</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='content'>
                <div className='row'>
                    <div className={`col-md-6 col-lg-8 col-xxl-6 ${stylesCollection.fullBleedFlushLeft}`}>
                        <figure>
                            <picture className='image'>
                            <img src={require('../../images/events/mark-belford-FairmontFashion-Lrg-1.webp')} alt='Model standing in front of mirror in the Butterfield 8 coat and dress' height='100%' width='100%' />
                            </picture>
                        </figure>
                    </div>
                    <div className='col-md-11 col-lg-5 col-xl-6'>
                        <div className='row'>
                            <div className='col-xxl-2'></div>
                            <div className='col-sm-10 col-md-12 col-xl-10 col-xxl-8'>
                                <div className='vertical-center-block'>
                                    <h2 style={{color:'#fd1d86'}}>New Tagline <br/>to go Here</h2>
                                    <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                                    <div className='e-comerce-link-2' onMouseEnter={() => {showHiddenText();}} onMouseLeave={() => {removeHiddenText();}} >
                                        <a href='https://www.markbelfordcouture.com/' target='blank' >Shop<br/>Mark Belford<br/>Couture</a>
                                        <div className='hidden-text-mask'></div>
                                        <div className='hidden-text'>
                                            You're<br/>Worth It!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='content middle-big-pad'>
                <div className='row'>
                    <div className='col-sm-6'>
                    
                            <p className='lrg top-adj-1'>
                            <br/><br/>Laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit animl.<br/>
                            <br/>
                            </p>
                        <div className='col-xs-12 col-lg-9 right-align'>
                            <picture className='image'>
                                <img src={require('../../images/events/mark-belford-FairmontFashion-Lrg-2.webp')} alt='Model looking into mirror in the Butterfield 8 coat and dress' height='auto' width='100%' />
                            </picture>
                        </div>
                        <div className='col-xs-12 col-lg-6 right-align'>
                            <picture className='image'>
                                <img src={require('../../images/events/mark-belford-FairmontFashion-Lrg-7.webp')} alt='Model looking into mirror in the Butterfield 8 coat and dress' height='auto' width='100%' />
                            </picture>
                        </div>
                        <div className='col-xs-12 col-lg-8 right-align'>
                            <p className='lrg'>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.<br/><br/></p>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='row'>
                            <div className='col-sm-8'>
                            <picture className='image'>
                                <img src={require('../../images/events/mark-belford-FairmontFashion-Lrg-3.webp')} alt='Model looking into mirror in the Butterfield 8 coat and dress' height='auto' width='100%' />
                            </picture>
                            </div>
                            <div className='col-sm-10'>
                                <picture className='image'>
                                    <img src={require('../../images/events/mark-belford-FairmontFashion-Lrg-4.webp')} alt='Model looking into mirror in the Butterfield 8 coat and dress' height='auto' width='100%' />
                                </picture>
                            </div>
                            <div className='col-sm-6'>
                                <picture className='image'>
                                    <img src={require('../../images/events/mark-belford-FairmontFashion-Lrg-6.webp')} alt='Model looking into mirror in the Butterfield 8 coat and dress' height='auto' width='100%' />
                                </picture>
                            </div>
                            <div className='col-sm-8'>
                                <picture className='image'>
                                    <img src={require('../../images/events/mark-belford-FairmontFashion-Lrg-5.webp')} alt='Model looking into mirror in the Butterfield 8 coat and dress' height='auto' width='100%' />
                                </picture>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-xs-12 col-md-6 col-xxl-9'>
                                <br/>
                                <h2 style={{color:'#fd1d86'}}>
                                    <span className={stylesCollection.quotePullBack}>'New Tagline</span><br/>to go Here'
                                </h2>
                            </div>
                        </div>
                    </div>               
                </div>
            </section>
            <FooterBanner bannerImage={bannerImage} />
        </main>
    );
};

export default CAFAawards;