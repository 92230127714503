import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements, Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import Root from '../components/Root';
import MarkBelfordWebsite from '../components/pages/Mark-Belford-Website';
import Mark from '../components/pages/Mark';
import Butterfield8 from '../components/pages/Butterfield8';
import AcademyGown from '../components/pages/AcademyGown';
import BurmeseRuby from '../components/pages/BurmeseRuby';
import CoronationGown from '../components/pages/CoronationGown';
import ParkAvenue from '../components/pages/ParkAvenue';
import HotelCalifornia from '../components/pages/HotelCalifornia';
import SwedishPrince from '../components/pages/SwedishPrince';
import FairmontFashion from '../components/pages/FairmontFashion';
import Publications from '../components/pages/Publications';
import Contact from '../components/pages/Contact';

const router = createBrowserRouter(createRoutesFromElements(
  <Route path='/' element={ <Root/> } >
    <Route exact path='/' element={<MarkBelfordWebsite />} />
    <Route path='Mark' element={<Mark/>} />
    {/*<Route path='Collection' element={<Collection/>} />*/}
    <Route path='Butterfield8' element={<Butterfield8/>} />
    <Route path='AcademyGown' element={<AcademyGown/>} />
    <Route path='BurmeseRuby' element={<BurmeseRuby/>} />
    <Route path='CoronationGown' element={<CoronationGown/>} />
    <Route path='ParkAvenue' element={<ParkAvenue/>} />
    <Route path='HotelCalifornia' element={<HotelCalifornia/>} />
    {/*<Route path='Events' element={<Events/>} />*/}
    <Route path='SwedishPrince' element={<SwedishPrince/>} />
    <Route path='FairmontFashion' element={<FairmontFashion/>} />
    <Route path='Publications' element={<Publications/>} />
    <Route path='Contact' element={<Contact/>} />
    <Route path="/*" element={<Navigate replace to="/" />} />
  </Route>
));

const NotFoundPage = () => {
  <div>
    <h1>404 - Page Not Found Sucka</h1>
    <p>We cant find your page. looks like someone screwed up!</p>
    <a href='/'>Go back home</a>
  </div>
}

export default function App(){
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <RouterProvider router={router} />
  );
};