import FooterBanner from '../footer/FooterBanner';
import bannerImage from '../../images/coronation-gown/mark-belford-coronation-gown-footer.webp';
import styles from '../styles/CoronationGown.module.css';
import stylesCollection from '../styles/Collections.module.css';
import showHiddenText from '../modules/ShowText';
import removeHiddenText from '../modules/RemoveText';


export default function CoronationGown(){
    return(
        <main>
            <div className='page-marker'></div>
            <section className='content content-top'>
                <div className='row center-it'>
                    <div className='col-sm-12'>
                        <div className='row'>
                            <div className='col-lg-1 col-xl-2 col-xxxl-4'></div>
                            <div className='col-sm-6 col-lg-4 col-xl-3 col-xxxl-2 order-md-2'>
                                <div className={styles.heroHeader}>
                                    <h1>
                                        <div className={styles.adj1}>the</div><div className={styles.adj2}>Coronation</div><div className={styles.adj3}>Gown</div>
                                    </h1>
                                </div>
                            </div>
                            <div className='col-sm-9 col-md-5 col-lg-4 col-xxxl-3 order-md-1'>
                                <picture className='image'>
                                    <source media='(max-width:500px)' srcSet={require('../../images/coronation-gown/mark-belford-coronation-gown-top.webp')} />
                                    <img src={require('../../images/coronation-gown/mark-belford-coronation-gown-top.webp')} alt='Model standing by mirror in the Butterfield 8 dress' height='auto' width='100%' />
                                </picture>
                            </div>
                            <div className='col-sm-12 order-md-3'>
                                <div className='row'>
                                    <div className='col-md-2 col-xl-3 col-xxl-4 col-xxxl-5'></div>
                                    <div className='col-md-4 col-xxl-3'>
                                        <div className={stylesCollection.contentTopParagraph}>
                                            <div className='top-text-lrg'>Experience<br/>the coronation<br/>of glamour</div>
                                            <p>
                                            with our breathtaking masterpiece, "The Coronation Gown." This resplendent couture creation in matte gold silk taffeta, meticulously hand draped by the visionary Mark Belford, showcases an extraordinary fusion of artistry <span className='no-wrap'>and precision.</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='content'>
                <div className='row'>
                    <div className="col-md-6 col-lg-6 col-xxl-6">
                        <div className={stylesCollection.fullBleed}>
                            <figure>
                                <picture className='image-full'>
                                    <source media='(max-width:500px)' srcSet={require('../../images/coronation-gown/mark-belford-coronation-gown-1-xs.webp')} />
                                    <source media='(max-width:824px)' srcSet={require('../../images/coronation-gown/mark-belford-coronation-gown-1-sm.webp')} />
                                    <source srcSet={require('../../images/coronation-gown/mark-belford-coronation-gown-1-lg.webp')} />
                                    <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing the Mark Belford Burmese Ruby dress' height='100%' width='100%' loading='lazy' />
                                </picture>
                            </figure>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-5 col-xl-6'>
                        <div className='row'>
                            <div className='col-xxl-2'></div>
                            <div className='col-sm-10 col-md-12 col-xl-10 col-xxl-8'>
                                <div className='vertical-center-block blk'>
                                    <h2 style={{color:'#fd1d86'}}>
                                        The Essence<br/>of Majesty<br/>and Elegance
                                    </h2>
                                    <p className='white-text'>Prepare to be captivated as razor-sharp pleats cascade from the bodice, effortlessly transitioning into a voluminous, tiered skirt that gracefully sweeps the floor. A touch of opulence awaits as the faux fur bodice, delicately attached to the sweetheart neckline with covered loops and buttons, adds an element of luxurious allure. Complete with a sumptuous gold silk lining throughout, this regal ensemble embodies the essence of majesty and elegance. {/*Allow yourself to be adorned in unrivaled splendor.
                                    Embark on a majestic journey of elegance and grandeur with "HRH The Coronation Gown." This extraordinary couture creation, draped with meticulous precision by the visionary Mark Belford, is a true testament to the fusion of artistry and glamour.*/}</p>
                                    <div className='e-comerce-link-2 blk-bg' onMouseEnter={() => {showHiddenText();}} onMouseLeave={() => {removeHiddenText();}} >
                                        <a href='https://www.markbelfordcouture.com/' target='blank' >Shop<br/>Mark Belford<br/>Couture</a>
                                        <div className='hidden-text-mask'></div>
                                        <div className='hidden-text'>
                                            You're<br/>Worth It!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='content middle-big-pad'>
                <div className='row'>
                    <div className='col-lg-6 order-lg-2 order-xs-1'>
                        <div className='row'>
                            <div className='col-md-10 col-lg-12 image'>
                                <picture className='image'>
                                    <source media='(max-width:500px)' srcSet={require('../../images/coronation-gown/mark-belford-coronation-gown-3-xs.webp')} />
                                    <source srcSet={require('../../images/coronation-gown/mark-belford-coronation-gown-3-lg.webp')} />
                                    <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing the Mark Belford Coronation Gown' height='100%' width='100%' loading='lazy' />
                                </picture>
                            </div>
                            <div className='col-md-10 col-lg-8'>
                                <picture className='image'>
                                    <source media='(max-width:500px)' srcSet={require('../../images/coronation-gown/mark-belford-coronation-gown-4-xs.webp')} />
                                    <source srcSet={require('../../images/coronation-gown/mark-belford-coronation-gown-4-lg.webp')} />
                                    <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing the Mark Belford Coronation Gown' height='100%' width='100%' loading='lazy' />
                                </picture>
                            </div>
                            <div className='col-xs-12 col-md-6 col-xxl-8'>
                                <h2 style={{color:'#fd1d86'}}>A Cocoon <br/>of Luxury</h2>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 order-lg-1 order-xs-2'>
                        <div className='row justify-content-end-lg'>
                            <p className='lrg top-adj-1'>
                            Indulge in the sumptuousness of gold silk lining that envelops you in a cocoon of luxury, elevating your presence to new heights. With "HRH The Coronation Gown," you will reign supreme in unrivalled splendor, embodying the very essence of majesty and elegance. Allow yourself to be adorned in the regal allure of this masterpiece, exclusively at your command.<br/>
                            <br/>
                            </p>
                            <div className='col-xs-12 col-md-8 col-lg-10 col-xxxl-8 image'>
                                <picture className='image'>
                                    <source media='(max-width:500px)' srcSet={require('../../images/coronation-gown/mark-belford-coronation-gown-2-xs.webp')} />
                                    <source srcSet={require('../../images/coronation-gown/mark-belford-coronation-gown-2-lg.webp')} />
                                    <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing the Mark Belford Coronation Gown' height='100%' width='100%' loading='lazy' />
                                </picture>
                            </div>
                            <p className='sml'>
                            <br/>
                            <br/>
                            Indulge in the sumptuousness of gold silk lining that envelops you in a cocoon of luxury, elevating your presence to new heights. With "HRH The Coronation Gown," you will reign supreme in unrivalled splendor, embodying the very essence of majesty and elegance. Allow yourself to be adorned in the regal allure of this masterpiece, exclusively at your command.<br/>
                            <br/>
                            <br/>
                            </p>  
                        </div>
                    </div>
                </div>
            </section>
            <FooterBanner bannerImage={bannerImage} />
        </main>
    );
};