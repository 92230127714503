import { useState } from 'react';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser'
import styles from '../../styles/Contact.module.css';
import { isVisible } from '@testing-library/user-event/dist/utils';
//import { onSubmit } from './formUtilities';





export default function ContactForm(){
    const [thankYouMessage, setThankYouMessage] = useState("");
    const [formStatus, setFormStatus] = useState("block");
    const { register, handleSubmit, reset, formState: {errors} } = useForm({
        defaultValues: {
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            comments: "",
        }
    });

    const formStyle = {display: formStatus};
    const hardReturn = <br/>;

    const onSubmit = (data: any) => {
        const templateParams = {
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.phone,
            email: data.email,
            comments: data.comments,
        };
        emailjs
        .send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, templateParams, process.env.REACT_APP_PUBLIC_KEY )
        .then(() => {
            console.log("Email sent successfully");
            reset();
            setFormStatus(() => {
                return "none";
            });
            //setFormStatus("none");
            setThankYouMessage(() => {
                return `Thank you ${data.firstName} for your interest in Mark Belford Couture. We will be in touch shortly.`;
            })
        }).catch((error) => {
            console.error("error", error);
        });
    }

    return (
        <>
        {thankYouMessage}
        <form onSubmit={handleSubmit(onSubmit)} style={formStyle} >
            <p className={styles.errorMessage}>{errors.firstName?.message}</p>
            <label for='firstName'></label>
            <input {...register('firstName', { required: 'Please enter your first name' })} placeholder='first name' />
            <p className={styles.errorMessage}>{errors.lastName?.message}</p>
            <label for='lastName'></label>
            <input {...register('lastName', { required: 'Please enter your last name', minLength: {value: 4, message: "Min length is 4"} })} placeholder='last name' />
            <p className={styles.errorMessage}>{errors.phone?.message}</p>
            <label for='phone'></label>
            <input {...register('phone', { required: 'Please enter your phone number', pattern: { value: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, message: 'Invalid phone number'} })}  placeholder='phone'></input>
            <p className={styles.errorMessage}>{errors.email?.message}</p>
            <label for='email'></label>
            <input {...register('email', { required: 'Please enter your email address', pattern: { value: /^\S+@\S+$/i, message: 'Invalid email address'}, })}  placeholder='email'></input>
            <label for='comments'></label>
            <textarea {...register('comments')} placeholder='comments' ></textarea>
            <button className={styles.submitBtn} type='submit'>Submit</button>
        </form>
        </>
    )
}