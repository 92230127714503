import React from 'react';
import '../styles/Scale.css';

export default function Scale(){
    return (
        <div className='bg-scale'>
        <div className='row'>
            <div className='col-sm-1 column'></div>
            <div className='col-sm-1 column'></div>
            <div className='col-sm-1 column'></div>
            <div className='col-sm-1 column'></div>
            <div className='col-sm-1 column'></div>
            <div className='col-sm-1 column'></div>
            <div className='col-sm-1 column'></div>
            <div className='col-sm-1 column'></div>
            <div className='col-sm-1 column'></div>
            <div className='col-sm-1 column'></div>
            <div className='col-sm-1 column'></div>
            <div className='col-sm-1 column'></div>
        </div>
    </div> 
    )
}

/*
class Scale extends React.Component{
    render(){
        return <div className='bg-scale'>
            <div className='row'>
                <div className='col-sm-1 column'></div>
                <div className='col-sm-1 column'></div>
                <div className='col-sm-1 column'></div>
                <div className='col-sm-1 column'></div>
                <div className='col-sm-1 column'></div>
                <div className='col-sm-1 column'></div>
                <div className='col-sm-1 column'></div>
                <div className='col-sm-1 column'></div>
                <div className='col-sm-1 column'></div>
                <div className='col-sm-1 column'></div>
                <div className='col-sm-1 column'></div>
                <div className='col-sm-1 column'></div>
            </div>
        </div>
    }
}

export default Scale;
*/