

import ContactForm from './components/ContactForm';
import styles from '../styles/Contact.module.css';

export default function Contact(){
    return(
        <main>
            <div className='page-marker'></div>
            <section className='content content-top fixed-top'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <h1 className='pull-left hot-pink'>Contact Mark</h1>
                    </div>
                </div>
                <div className="row">
                    <div className='col-md-1'></div>
                    <div className="col-md-3">
                        <div className='spec-text-1'>
                        <span className='no-wrap'>Ever dreamed of</span><br/>
                        <span className='no-wrap'>owning a couture</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                <div className="col-md-6">
                        <div className={`spec-text-10 ${styles.wrkOfArt}`}>
                            work<br/>of art<div className={`benguiatCaslon ${styles.qstnMrkAdj}`}>¿</div>
                        </div>
                        <div className={styles.regTextBlk}>
                            Or perhaps you just want to be kept in the loop regarding the world of Mark Belford's couture creations and events? Please fill out the form below and lets <span className='no-wrap'>get acquainted.</span><br/><br/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className='col-lg-1'></div>
                    <div className={`col-sm-5 col-md-4 col-lg-3 col-xxl-2 spec-text-1 ${styles.dsrTxt}`}>
                        <span className='no-wrap'>Let's turn your</span><br/><span className='no-wrap'>desire into</span><br/>something <span className='no-wrap'>dangerously chic.</span>
                    </div>
                </div>
                <div className={`row ${styles.contactForm}`}>
                    <div className='col-md-1'></div>
                    <div className='col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-4'>
                        <ContactForm />
                    </div>
                </div>
            </section>
        </main>
    );
};








/*
    const onSubmit = (data) => {
        const emailParams = {
            from_firstName: data.firstName,
            from_lastName: data.lastName,
            from_phone: data.phone,
            from_email: data.email,
        }
        emailjs
            .sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, emailParams, process.env.REACT_APP_PUBLIC_KEY)
            .then(() => {
                console.log("Email sent successfully");
                reset();
            })
            .catch((error) => {
                console.log(error);
            });
    };
    */








/*const thankYouReplace = () => {
        const contactForm = document.querySelector('.contact-form');
        const thankYouMsg = document.querySelector('.thank-you');
        contactForm.classList.toggle('off');
        thankYouMsg.classList.add('on');
    }  */








/*
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        comments: "",
    });

    const [errors, setErrors] = useState();

    const isValidEmail = (email) => {
        // Regular expression for basic email validation
        const emailRegex = /^\S+@\S+\.\S+$/;
        return emailRegex.test(email);
    };

    const isValidPhoneNumber = (phone) => {
        // Regular expression for basic phone number vallidation (10 digits)
        const phoneRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        return phoneRegex.test(phone);
    };

    const validateForm = () => {
        let newErrors = {};

        if(!formData.firstName){
            newErrors.firstName = "Your first name is required";
        }
        if(!formData.lastName){
            newErrors.lastName = "Your last name is required";
        }
        if(!formData.phone){
            newErrors.phone = "Your phone number is required";
        } else if(!isValidPhoneNumber(formData.phone)){
            newErrors.phone = "Please enter a valid phone number"
        }
        if(!formData.email){
            newErrors.email = "Your email address is required";
        } else if(!isValidEmail(formData.email)){
            newErrors.email = "Invalid email format";
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    console.log(errors);

    const handleSubmit = (e) => {
        e.preventDefault(); 
        
        const isValid = validateForm();
        if(isValid){
            console.log("Form Submitted", formData);
        } else {
            console.log("Form Validation Failed");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
*/
    

    


    /*
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const handleInputFirstName = (e) => {
        setFirstName(e.target.value)
    }
    const handleInputLastName = (e) => {
        setLastName(e.target.value)
    }
    const handleInputPhone = (e) => {
        setPhone(e.target.value)
    }
    const handleInputEmail = (e) => {
        setEmail(e.target.value)
    }
    const handleInputComments = (e) => {
        setMessage(e.target.value)
    }
    
    const thankYouReplace = () => {
        const contactForm = document.querySelector('.contact-form');
        const thankYouMsg = document.querySelector('.thank-you');
        contactForm.classList.toggle('off');
        thankYouMsg.classList.add('on');
    }
 
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, e.target, process.env.REACT_APP_PUBLIC_KEY )
        .then((result) => {
            console.log(result.text);
            thankYouReplace();
        }, (error) => {
            console.log(error.text);
        });
    }

    const inputError = () => {
        return <div>error</div>
    }
   */